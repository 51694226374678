<template>
  <div class="row">
    <!-- 독성여부 체크 -->
    <c-card title="LBL0002350" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- 저장 -->
          <c-btn
            v-if="!disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="save"
            :mappingType="saveType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveAccident"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 독성여부 -->
          <c-radio
            :editable="editable"
            :disabled="disabled"
            :comboItems="toxicFlagItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="toxicFlag"
            label="LBL0002351"
            v-model="tabParam.toxicFlag"
          ></c-radio>
        </div>
      </template>
    </c-card>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <!-- 사고관련 설비 목록 -->
      <!-- 설비를 추가하세요. -->
      <c-table
        ref="equipTable"
        title="LBL0002352"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :isExcelDown="false"
        :noDataLabel="$message('MSG0000703')"
        :hideBottom="true"
        :editable="editable"
        rowKey="equipmentCd"
        selection="multiple"
        @linkClick="linkClick1"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <!-- 추가 -->
            <c-btn v-if="editable && !disabled" :showLoading="false" label="LBLADD" icon="add" @btnClicked="addEquipment" />
            <!-- 제외 -->
            <c-btn v-if="editable && !disabled && grid.data.length > 0" :showLoading="false"  label="LBLEXCEPT" icon="remove" @btnClicked="removeEquipment" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" topClass="topcolor-orange">
      <!-- 사고관련 화학물질 목록 -->
      <!-- 화학물질을 추가하세요. -->
      <c-table
        ref="chemTable"
        title="LBL0002353"
        :columns="grid2.columns"
        :gridHeight="grid2.height"
        :data="grid2.data"
        :filtering="false"
        :isExcelDown="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :noDataLabel="$message('MSG0000704')"
        :editable="editable && !disabled"
        rowKey="materialCd"
        selection="multiple"
        @linkClick="linkClick2"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 추가 -->
            <c-btn v-if="editable && !disabled" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addChem" />
            <!-- 제외 -->
            <c-btn v-if="editable && !disabled && grid2.data.length > 0" label="LBLEXCEPT" :showLoading="false" icon="remove" @btnClicked="removeChem" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>

import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'accident-equipment',

  props: {
    tabParam: {
      type: Object,
      default: () => ({
        plantCd: '',
        iimAccidentId: '',
        toxicFlag: 'N',
        accidentMaterialModelList: [],
        accidentStatusCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            // 설비유형
            label: 'LBL0000633',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            // 설비관리번호
            label: 'LBL0000631',
            style: 'width:100px',
            type: 'link',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            // 설비명
            label: 'LBL0000632',
            style: 'width:200px',
            align: 'left',
            sortable: false
          },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            // 관련법규
            label: 'LBL0002354',
            align: 'left',
            style: 'width:300px',
            sortable: false
          },
        ],
        data: [],
        height: '280px'
      },
      grid2: {
        columns: [
          {
            name: 'materialCd',
            field: 'materialCd',
            type: 'link',
            // 자재코드
            label: 'LBL0002355',
            align: 'center',
            sortable: false

          },
          {
            name: 'casNo',
            field: 'casNo',
            // casNo
            label: 'LBL0002357',
            align: 'center',
            sortable: false

          },
          {
            name: 'materialName',
            field: 'materialName',
            label: 'LBL0002356',
            align: 'center',
            sortable: false
          },
        ],
        data: [],
        height: '280px'
      },
      toxicFlagItems: [
        { code: 'Y', codeName: 'YES' },
        { code: 'N', codeName: 'NO' },
      ],
      save: {
        plantCd: '',
        iimAccidentId: '',
        toxicFlag: 'N',
        accidentMaterialModelList: [],
        accidentStatusCd: '',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveUrl: 'transactionConfig.sop.iim.accident.process.insert.url',
      saveType: 'POST',
      isSave: false,
      title: '',
    };
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.tabParam.accidentStatusCd) && this.tabParam.accidentStatusCd === 'ISPC000005'
    }
  },
  watch: {
    'grid.data'() {
      this.tabParam.accidentMaterialModelList = (this.$_.concat(this.grid.data, this.grid2.data));
    },
    'grid2.data'() {
      this.tabParam.accidentMaterialModelList = (this.$_.concat(this.grid.data, this.grid2.data));
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      
      this.updateUrl = transactionConfig.sop.iim.accident.process.update.relate.url;

      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$_.forEach(this.tabParam.accidentMaterialModelList, _item => {
        if (_item.equipmentMaterialTypeCd === 'EQUIP') {
          this.grid.data.push(_item) // 설비
        } else {
          this.grid2.data.push(_item) // 화학자재
        }
      })
    },
    /* eslint-disable no-unused-vars */ 
    addEquipment() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = 'LBL0002358'; // 사고설비 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.grid.data, {
            equipmentCd: _item.equipmentCd,
          });
          if (index === -1) {
            this.grid.data.splice(0, 0, {
              iimAccidentId: this.tabParam.iimAccidentId,
              equipmentMaterialTypeCd: 'EQUIP',
              managementDepts: _item.managementDepts,
              equipmentTypeName: _item.equipmentTypeName,
              checkDeptCd: _item.managementDepts,
              equipmentName: _item.equipmentName,
              equipmentNo: _item.equipmentNo,
              equipmentCd: _item.equipmentCd,
              relatedLawsName: _item.relatedLawsName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId
            })
          }
        })
      }
    },
    removeEquipment() {
      let selectData = this.$refs['equipTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data = this.$_.reject(this.grid.data, { equipmentCd: item.equipmentCd })
        })
      }
    },
    addChem() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = 'LBL0002359'; // 사고 화학자재 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/chemMaterialPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeChemPopup;
    },
    closeChemPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.grid2.data, {
            mdmChemMaterialId: _item.mdmChemMaterialId,
          });
          if (index === -1) {
            this.grid2.data.splice(0, 0, {
              iimAccidentId: this.tabParam.iimAccidentId,
              equipmentMaterialTypeCd: 'CHEM',
              materialCd: _item.materialCd,
              mdmChemMaterialId: _item.mdmChemMaterialId,
              casNo: _item.casNo,
              materialName: _item.materialName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId
            })
          }
        })
      }
    },
    removeChem() {
      let selectData = this.$refs['chemTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid2.data = this.$_.reject(this.grid2.data, item)
        });
      }
    },
    saveAccident() {
      this.saveUrl = this.updateUrl;
      this.saveType = 'PUT';
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.tabParam.accidentMaterialModelList = 
          this.$_.concat(this.grid.data, this.grid2.data);

          if(this.tabParam.iimAccidentId) {
            this.$set(this.save, 'iimAccidentId',  this.tabParam.iimAccidentId);
            this.$set(this.save, 'plantCd',  this.tabParam.plantCd);
            this.$set(this.save, 'toxicFlag',  this.tabParam.toxicFlag);
            this.$set(this.save, 'accidentMaterialModelList',  this.tabParam.accidentMaterialModelList);
            this.$set(this.save, 'accidentStatusCd',  this.tabParam.accidentStatusCd);
          }
          
          this.tabParam.regUserId = this.$store.getters.user.userId
          this.tabParam.chgUserId = this.$store.getters.user.userId
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    linkClick1(data) {
      this.popupOptions.title = 'LBL0002360'; // 관련 설비 상세
      this.popupOptions.param = 
      this.popupOptions.param = {
        equipmentCd: data ? data.equipmentCd : '',
        psmFlag: data ? data.psmFlag : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    linkClick2(data) {
      this.popupOptions.title = 'LBL0002361'; // 관련 화학물질 상세
      this.popupOptions.param = 
      this.popupOptions.param = {
        mdmChemMaterialId: data ? data.mdmChemMaterialId : '',
        plantCd: data ? this.tabParam.plantCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chemDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
